<template>
  <div class="grid-x">
    <div class="cell small-12 medium-8 bg-grey pb-5">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1">
          <div class="headline-box">
            <h1 class="heavy text-center text-white">
              Budget<br />
              Impact<br />
              Model
            </h1>
          </div>
        </div>
        <div class="cell small-12 medium-10 medium-offset-1 pt-5 pb-5">
          <span class="inactive-link">This link is not yet active</span>
        </div>
        <div class="cell small-12 medium-10 medium-offset-1 pt-10">
          <p class="text-ae white-border text-white text-center pa-3 mb-0">
            Adverse events should be reported.<br />
            UK: Reporting forms and information can be found at https://yellowcard.mhra.gov.uk/.<br />
            Adverse events should also be reported to Biogen Idec Limited.<br />
            Tel: +44(0)20 360 886 22. Email: medinfo.europe@biogen.com
          </p>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 medium-5 medium-offset-1 mb pt-1">
          <p class="text-white pi-info">
            Prescribing information can be found via the PI<br />
            button in the top right hand corner of every page.
          </p>
        </div>
        <div class="cell small-12 medium-5 mb pt-3">
          <p class="text-small text-white text-right">
            Biogen-192038. Date of preparation: March 2023
          </p>
        </div>
      </div>
    </div>
    <div class="cell small-12 medium-4">
      <div :style="{ height: viewportHeight + 'px' }" class="relative">
        <img class="top-logo" src="../assets/logos/Biogen_logo.png" alt="" />
        <img class="bottom-logo" src="../assets/logos/Byooviz_logo.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InactiveLink',
  data() {
    return {
      links: [],
      viewportHeight: 0,
    };
  },
  mounted() {
    this.viewportHeight = window.innerHeight;
  },
};
</script>
